<template>
  <div class="row h-100 m-0">
    <div class="col-2 h-100">
      <div class="text-center" style="height: 50px">
        <h3 class="p-2">{{ globalStore.contextID }}</h3>
      </div>
      <perfect-scrollbar class="overflow-hidden" style="height: calc(100vh - 50px)" :options="{suppressScrollX: true, wheelPropagation: true}">
        <div
            v-for="(element, index) in translationsStore.translations[globalStore.contextID]"
            :key="index"
            style="border: 1px solid black"
            class="px-3 my-2"
            :class="isCurrent(index, globalStore.contextID) ? 'bg-success' : ''"
            @click="translationsStore.setCurrentElementAction(index, globalStore.contextID)">
          <p class="my-3">{{index}}</p>
        </div>
      </perfect-scrollbar>
    </div>
    <div class="col-10 vh-100">
      <div v-if="!translationsStore.currentElementID" class="vh-100 d-flex justify-content-center align-items-center">
        {{ t('chooseElementLeftBarElement') }}
      </div>
      <TranslationEditor v-else />
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import TranslationEditor from '../components/TranslationEditor.vue';
import useGlobalStore from '../stores/globalStore';
import useTranslationStore from '../stores/translationsStore';

const globalStore = useGlobalStore();
const translationsStore = useTranslationStore();
const { t } = useI18n();
translationsStore.getElementsAction(globalStore.eventID, globalStore.contextID);

function isCurrent(elementID, contextID) {
  return elementID === translationsStore.currentElementID && contextID === translationsStore.currentContextID;
}
</script>

<style scoped>
@import 'vue3-perfect-scrollbar/style.css';

</style>
