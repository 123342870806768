<template>
  <img @load="onLoaded" style="max-width: 400px; height: auto" :src="image" alt="">
  <div v-if="!loaded">
    <PulseLoader />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

const loaded = ref(false);
const { image } = defineProps(['image']);

function onLoaded() {
  loaded.value = true;
}
</script>

<style scoped>

</style>
