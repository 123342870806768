/**
     * Get elements from firebase in translations node and set it in store
     * Used for all contexts (agendas, speakers...) but has special behavior for webapp
     * @param {String} eventID - The ID of the event
     * @param {String} context - The ID of the context (optional)
     * @param {String} elementID - The ID of the element (optional)
     * @returns {Promise<void>}
     */
import i18n from '@/main';
import getSupportedLanguages from '@/services/gcloudTranslateService';
import {
  deleteItem,
  getElements,
  getWebappDictionary,
  getWebappElements,
  setContext,
  setElement,
} from '@/services/translationsService';
import useGlobalStore from '@/stores/globalStore';
import { getSlug } from '@/utils';
import { notify } from '@kyvg/vue3-notification';
import Papa from 'papaparse';

async function getElementsAction(eventID, context = '', elementID = '') {
  const globalStore = useGlobalStore();
  let elements;
  if (context === 'webapp') {
    elements = await getWebappElements(eventID, globalStore.currentAttr);
    this.webappDictionary = await getWebappDictionary();
  } else {
    elements = await getElements(eventID, globalStore.currentAttr, context, elementID);
  }

  this.defineDefaultElementAction(elements);
  if (context === 'webapp') {
    this.translations = elements;
    await this.defineWebappElementsAction();
  }
  this.contentLoaded = true;
}

/**
 * Define the current element to be edited
 * @param elementID - The ID of the element
 * @param contextID - The ID of the context
 */
function setCurrentElementAction(elementID, contextID) {
  if (this.currentElementID === elementID && this.currentContextID === contextID) {
    this.currentElementID = '';
    this.currentContextID = '';
    this.currentTranslation = {};
  } else {
    const globalStore = useGlobalStore();
    const defaultLang = getSlug(globalStore.getFirstLocale);
    this.setLangAction(defaultLang);
    this.currentElementID = elementID;
    this.currentContextID = contextID;
    this.currentTranslation = this.translations[contextID][elementID];
    this.translationLoaded = true;
  }
}

/**
 * Create default element to avoid undefined element
 * @return void
 */
function defineDefaultElementAction(getElement) {
  const globalStore = useGlobalStore();

  if (globalStore.contextID) {
    this.translations[globalStore.contextID] = {};
    if (globalStore.elementID) {
      this.translations[globalStore.contextID][globalStore.elementID] = {};
      if (getElement[globalStore.contextID] && getElement[globalStore.contextID][globalStore.elementID]) {
        this.translations[globalStore.contextID][globalStore.elementID] = getElement[globalStore.contextID][globalStore.elementID];
      }
      for (const locale of globalStore.getEventLocales) {
        const sluggedLocale = getSlug(locale);
        if (!this.translations[globalStore.contextID][globalStore.elementID][sluggedLocale]) {
          this.translations[globalStore.contextID][globalStore.elementID][sluggedLocale] = {};
        }
        for (const attr of globalStore.currentAttr) {
          if (!this.translations[globalStore.contextID][globalStore.elementID][sluggedLocale][attr]) {
            this.translations[globalStore.contextID][globalStore.elementID][sluggedLocale][attr] = '';
          }
        }
      }
    }
  }
}

/**
 * Define which language is displayed
 * @param value - new value
 * @return void
 */
function setLangAction(value) {
  const globalStore = useGlobalStore();
  this.currentEditLang = value;
  if (!this.currentTranslation[value]) {
    this.currentTranslation[value] = {};
    for (const attr of globalStore.currentAttr) {
      this.currentTranslation[value][attr] = '';
    }
  }
}

/**
 * Send the translation to database
 * @return {Promise<void>}
 */
async function sendCurrentTranslationAction() {
  const globalStore = useGlobalStore();

  globalStore.setSaveInProgressAction(true);

  if (globalStore.contextID === 'webapp') {
    const webappTranslations = { ...this.webappBaseTranslations, ...this.webappCustomTranslations };
    await setContext(globalStore.eventID, globalStore.contextID, globalStore.eventLocales, webappTranslations);
  } else {
    await setElement(globalStore.eventID, this.currentContextID, this.currentElementID, this.currentTranslation);
  }
  globalStore.setSaveInProgressAction(false);
  notify({
    type: 'success',
    title: i18n.global.t('notifications.saveTitle'),
    text: i18n.global.t('notifications.saveMessage'),
  });
}

function setWebappElementAction(translationType, translationKey, translationValue) {
  if (translationType === 'base') {
    this.webappBaseTranslations[translationKey][this.currentEditLang] = translationValue.trim();
  } else {
    this.webappCustomTranslations[translationKey][this.currentEditLang] = translationValue.trim();
  }
}

/**
 * This function parse webapp elements to be app compliant
 * @return {Promise<void>}
 */
async function defineWebappElementsAction() {
  const globalStore = useGlobalStore();
  for (const locale of globalStore.eventLocales) {
    if (globalStore.currentAttr.length) {
      for (const attr of globalStore.currentAttr) {
        if (!this.webappCustomTranslations[attr]) {
          this.webappCustomTranslations[attr] = {};
        }
        this.webappCustomTranslations[attr][locale] = '';
      }
    } else {
      const dictionary = this.webappDictionary[locale] || this.webappDictionary[globalStore.getFirstLocale] || this.webappDictionary.fr;
      for (const attr of Object.keys(dictionary)) {
        if (!this.webappBaseTranslations[attr]) {
          this.webappBaseTranslations[attr] = {};
        }
        this.webappBaseTranslations[attr][locale] = '';
      }
    }
  }
  if (Object.prototype.hasOwnProperty.call(this.translations, 'webapp') && Object.keys(this.translations.webapp).length) {
    for (const attr of Object.keys(this.translations.webapp)) {
      for (const locale of globalStore.eventLocales) {
        if (!this.translations.webapp[attr][locale]) continue;
        if (this.webappBaseTranslations[attr]) {
          this.webappBaseTranslations[attr][locale] = this.translations.webapp[attr][locale];
        } else {
          if (!this.webappCustomTranslations[attr]) {
            this.webappCustomTranslations[attr] = {};
          }
          this.webappCustomTranslations[attr][locale] = this.translations.webapp[attr][locale];
        }
      }
    }
  }
  const defaultLang = getSlug(globalStore.eventLocales[0]);
  this.setLangAction(defaultLang);
  this.webappLoaded = true;
}

/**
 * Remove webapp item in database and local data
 * @param itemKey
 * @return {Promise<void>}
 */
async function deleteWebappItemAction(itemKey) {
  const globalStore = useGlobalStore();
  Reflect.deleteProperty(this.webappCustomTranslations, itemKey);
  await deleteItem(globalStore.eventID, globalStore.contextID, globalStore.eventLocales, itemKey);
  notify({
    type: 'success',
    title: i18n.global.t('notifications.deleteSuccessTitle'),
    text: i18n.global.t('notifications.deleteSuccessMessage', { key: itemKey }),
    duration: 3000,
  });
}

/**
 * Add a webapp item in local data
 * @param newKey
 * @param newValue
 * @return {boolean}
 */
function addWebappItemAction(newKey, newValue) {
  const globalStore = useGlobalStore();

  if (this.webappBaseTranslations[newKey]) {
    notify({
      type: 'error',
      title: i18n.global.t('notifications.errorCreateItem'),
      text: i18n.global.t('notifications.errorBaseKeyMessage'),
    });
    return false;
  } if (this.webappCustomTranslations[newKey]) {
    notify({
      type: 'error',
      title: i18n.global.t('notifications.errorCreateItem'),
      text: i18n.global.t('notifications.errorCustomKeyMessage'),
    });
    return false;
  }
  for (const locale of globalStore.eventLocales) {
    if (!this.webappCustomTranslations[newKey]) {
      this.webappCustomTranslations[newKey] = {};
    }
    if (locale === this.currentEditLang) {
      this.webappCustomTranslations[newKey][locale] = newValue;
    } else {
      this.webappCustomTranslations[newKey][locale] = '';
    }
  }
  notify({
    type: 'success',
    title: i18n.global.t('notifications.createSuccessTitle', { key: newKey }),
    text: i18n.global.t('notifications.createSuccessMessage'),
  });
  return true;
}

/**
 * This function take all translations and export it into a csv file with ';' separator
 */
function exportToCSVAction() {
  const globalStore = useGlobalStore();
  const result = [];
  // We take the event locales from the global store
  const header = [...globalStore.getEventLocales];
  /**
   * This function takes a translation and parse it to adding it in result
   * @param translation
   */
  const parseEntriesToArray = (translation) => {
    const temp = {
      key: translation[0],
    };
    for (const key of header) {
      // If there is a translation available in the basic dictionary of the webapp, we're going to put it in the exported file to help translation
      if (this.webappDictionary[key]?.[translation[0]]) {
        temp[key] = this.webappDictionary[key][translation[0]];
      }
      if (translation[1][key]) {
        temp[key] = translation[1][key];
      } else if (!temp[key]) {
        temp[key] = '';
      }
    }
    result.push(temp);
  };
  /**
   * This function convert a non webapp translation to the same structure to ease the process
   * @param translation
   * @returns {{}} - A new entry with webapp translation structure
   */
  const convertObjectToEntry = (translation) => {
    const temp = {};
    for (const attribute of globalStore.currentAttr) {
      if (!temp[attribute]) {
        temp[attribute] = {};
      }
      /*
       first param will be an event locale
       second param will be the translation
       */
      if (translation[1][attribute]) {
        temp[attribute][translation[0]] = translation[1][attribute];
      } else {
        temp[attribute][translation[0]] = '';
      }
    }
    return temp;
  };
  if (globalStore.contextID === 'webapp') {
    for (const translation of Object.entries(this.webappBaseTranslations)) {
      parseEntriesToArray(translation)
    }
    for (const translation of Object.entries(this.webappCustomTranslations)) {
      parseEntriesToArray(translation)
    }
  } else {
    const entries = {};
    for (const translation of Object.entries(this.currentTranslation)) {
      const tempEntry = convertObjectToEntry(translation);
      /*
       We iterate on tempEntry to set entries with the same structure as webapp translations array
       entry[0] will be the key
       entry[1] will be the translation in the webapp translation structure
       */
      for (const entry of Object.entries(tempEntry)) {
        entries[entry[0]] = { ...entries[entry[0]], ...entry[1] };
      }
    }
    for (const translation of Object.entries(entries)) {
      parseEntriesToArray(translation);
    }
  }
  // We use Papa parse to unparse the array into a csv format string
  const csv = Papa.unparse(result, {
    delimiter: ';',
  });
  // we define the content of the file with some csv specifics data
  const resultFile = `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURIComponent(csv)}`;
  /*
   We create a link to initiate the download of the file
   */
  const link = document.createElement('a');
  link.href = resultFile;
  link.target = '_blank';
  link.download = `export_translations_${globalStore.eventID}_${Date.now()}.csv`;

  link.click();
}

/**
 * This function take imported data as parameters and will merge with current translations
 * @param newTranslations - an array containing translations as object
 */
function importTranslationsAction(newTranslations) {
  const globalStore = useGlobalStore();
  const languages = [...globalStore.getEventLocales];
  // This function will create a temporary object and will spread it in translations group
  const addElementToTranslation = (element, where) => {
    // If element.enabled is false, it should not be import
    if (!element.enabled || element.hidden) return;
    const result = {};
    // Will iterate on all keys in element
    for (const [key, value] of Object.entries(element)) {
      /*
       "key" and "enabled" are only dev-specific keys, should not be stored in database
       If key is including in event locales and value is not empty, it should be merged
       */
      if (key !== 'key' && key !== 'enabled' && languages.includes(key) && value !== ''
        && this.webappDictionary[key]?.[element.key] !== value) {
        result[key] = value;
      }
    }
    /* If where is "base", it should merge in webappBaseTranslations
     If where is "custom", it should merge in webappCustomTranslations
     If where is "global", it should merge in currentTranslation
     */
    if (where === 'base') {
      this.webappBaseTranslations[element.key] = { ...this.webappBaseTranslations[element.key], ...result };
    } else if (where === 'custom') {
      this.webappCustomTranslations[element.key] = { ...this.webappCustomTranslations[element.key], ...result };
    } else if (where === 'global') {
      /*
       As the currentTranslation not in the same design as webappTranslations, We need to iterate on eventLocales to set translation
       */
      for (const lang of languages) {
        if (result[lang]) {
          this.currentTranslation[lang][element.key] = result[lang];
        }
      }
    }
  };
  for (const element of newTranslations) {
    if (globalStore.contextID !== 'webapp') {
      addElementToTranslation(element, 'global');
    } else if (this.webappBaseTranslations[element.key]) {
      addElementToTranslation(element, 'base');
    } else if (this.webappCustomTranslations[element.key]) {
      addElementToTranslation(element, 'custom');
    }
  }
  // Display a notification to tell user the data has been set
  notify({
    type: 'success',
    title: i18n.global.t('notifications.importSuccess'),
    text: i18n.global.t('notifications.importSuccessMessage'),
  });
}

/**
 * This function will get the available languages for translation in Cloud Translate API and stores it in the store.
 */
function getAvailableAutoLanguagesAction() {
  const globalStore = useGlobalStore();
  getSupportedLanguages(globalStore.userLang).then((data) => {
    const defaultLanguagesCodes = ['en', 'de', 'es', 'it', 'nl', 'pt'];
    this.availableAutoLanguages = data.filter((language) => {
      if (defaultLanguagesCodes.includes(language.language)) {
        this.defaultAutoLanguages.push(language);
        return false;
      }
      return true;
    });
    this.autoTranslateAvailable = true;
    this.autoTranslateSourceLang = 'fr';
  });
}

export default {
  getElementsAction,
  setCurrentElementAction,
  defineDefaultElementAction,
  importTranslationsAction,
  exportToCSVAction,
  addWebappItemAction,
  deleteWebappItemAction,
  defineWebappElementsAction,
  setWebappElementAction,
  sendCurrentTranslationAction,
  setLangAction,
  getAvailableAutoLanguagesAction,
};
