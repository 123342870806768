<template>
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-fullscreen-md-down">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ t('importFileModal.title') }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="cleanImport()"></button>
        </div>
        <div class="modal-body">
          <div class="text-center" v-if="!isUploaded">
            <button class="btn btn-secondary" @click="() => fileInput.click()">{{ t('importFileModal.uploadFileButton') }}</button>
            <input class="d-none" ref="fileInput" type="file" @change="loadFile" />
          </div>
          <div v-else class="w-100">
            <div class="row mb-3">
              <div class="col-9 my-auto">
                <p v-html="t('importFileModal.infoText')"></p>
              </div>
              <div class="col-3">
                <button class="btn btn-primary float-end me-4" @click="selectAll()">{{ isAllSelected ? t('importFileModal.unselectAll') : t('importFileModal.selectAll') }}</button>
              </div>
            </div>
            <perfect-scrollbar :options="{suppressScrollX: true}">
              <template v-for="item in result">
                <div class="row border px-3 py-3" v-if="!item.hidden" v-bind:key="item.key" :class="item.enabled === true ? 'bg-success text-light' : 'bg-danger'">
                  <div class="col-10">
                    <h5>{{ item.key }}</h5>
                    <ul class="list-unstyled mb-0">
                      <li v-for="language in globalStore.getEventLocales" v-bind:key="`${item.key}-${language}`">
                        {{ language }} : {{ item[language] && item[language] !== '' ? item[language] : t('importFileModal.noTranslation') }}
                      </li>
                    </ul>
                  </div>
                  <div class="col-2 text-center my-auto">
                    <input type="checkbox" v-model="item.enabled" class="btn-check" :id="`btn-check-${item.key}`" autocomplete="off">
                    <label class="btn btn-primary" :for="`btn-check-${item.key}`">{{ item.enabled ? t('importFileModal.deleteButton') : t('importFileModal.addButton') }}</label><br>
                  </div>
                </div>
              </template>
            </perfect-scrollbar>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="cleanImport()">{{ t('cancel') }}</button>
          <button type="button" v-if="isUploaded"  class="btn btn-primary" data-bs-dismiss="modal" @click="translationStore.importTranslationsAction(result)">{{ t('save') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import useGlobalStore from '@/stores/globalStore';
import useTranslationStore from '@/stores/translationsStore';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import { parse } from 'papaparse';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';

const fileInput = ref(null);
const isUploaded = ref(false);
const isAllSelected = ref(true);
const result = ref([]);
const { t } = useI18n();
const globalStore = useGlobalStore();
const translationStore = useTranslationStore();

function loadFile(e) {
  const file = e.target.files[0];
  if (!file.name.includes('.csv')) {
    return;
  }
  const fileReader = new FileReader();
  fileReader.addEventListener('load', () => {
    const json = parse(fileReader.result, {
      delimiter: ';',
    }).data;
    const header = json[0];
    json.shift();
    for (const line of json) {
      const object = {};
      let uselessTranslation = 0;
      for (let i = 0; i < line.length; i += 1) {
        if (translationStore.webappDictionary[header[i]] && translationStore.webappDictionary[header[i]][line[0]] === line[i]) {
          object[header[i]] = '';
        } else {
          object[header[i]] = line[i];
        }
        if (line[i] === '' || object[header[i]] === '') {
          uselessTranslation += 1;
        }
      }
      if (uselessTranslation === line.length - 1) {
        object.hidden = true;
      }
      object.enabled = true;
      result.value.push(object);
    }
    isUploaded.value = !isUploaded.value;
  });
  fileReader.readAsText(file);
}

function selectAll() {
  for (let i = 0; i < result.value.length; i += 1) {
    result.value[i].enabled = !isAllSelected.value;
  }

  isAllSelected.value = !isAllSelected.value;
}

function cleanImport() {
  result.value = [];
  isAllSelected.value = true;
  isUploaded.value = false;
}
</script>

<style>
@import 'vue3-perfect-scrollbar/style.css';

.ps {
  height: 65vh;
}
</style>
